import React, { useCallback, useRef, useState } from 'react';
import './ChatMessage.scss';
import { useTranslation } from 'react-i18next';
import { MessageLoadingPayload, MessageRequest } from '../../ChatTypes';
import { getDateTime } from '../../../../utils/getDateTime/getDateTime';
import { getMessageStatusIcon, messageTypeComponents } from './helpers';
import { useTooltip } from '../../../../components/Modals/ToolTip/utils';
import { useMobile } from '../../../../components/utils/hooks';
import { ReactComponent as MessageTail } from '../../../../assets/message-tail.svg';
import MessageLoadingType from '../MessageTypes/MessageLoadingType';
import ToolTip from '../../../../components/Modals/ToolTip';
import MobileInfo from '../../../../components/Modals/MobileInfo';


type Props = {
  message: MessageRequest;
};

const ChatMessage: React.FC<Props> = React.memo(({ message }) => {
  const isMobile = useMobile();
  const { t } = useTranslation();
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [isMultiline, setIsMultiline] = useState(true);
  const [infoOpen, setInfoOpen] = useState<boolean>(false);
  const MessageView = messageTypeComponents.get(message.messageType);
  const errorRef = useRef<HTMLDivElement>(null);
  const { viewPopupOn: onErrorEnter, viewPopupOff: onErrorLeave, viewPopup: viewPopupError } = useTooltip();

  const handleSizeChange = useCallback(
    (height: number) => {
      if (height <= 30) {
        setIsMultiline(false);
      } else {
        setIsMultiline(true);
      }
    },
    [setIsMultiline]
  );

  const onInfoClick = () => {
    if (isMobile) setInfoOpen(!infoOpen);
  };

  const errorText = message.errorText || t(`chats.error.${message.errorType}`);

  return isFileLoaded ? null : (
    <>
      {viewPopupError && (
        <ToolTip refElement={errorRef} tail>
          <p className="chatMessage__errorText smallText">
            {errorText.length > 100 ? errorText.slice(0, 100) + '...' : errorText}
          </p>
        </ToolTip>
      )}
      <div className={`chatMessage ${message.direction}`}>
        <div
          className={`message ${message.direction}
        ${message.errorAt ? 'error' : ''}`}
        >
          <div className="mediaContainer">
            {message.author && (
              <p className="author">
                {message.author.name}&nbsp;{message.author.phone}
              </p>
            )}
            {MessageView ? (
              <MessageView message={message} handleSizeChange={handleSizeChange} />
            ) : (
              <MessageLoadingType
                message={message as MessageRequest & MessageLoadingPayload}
                handleSizeChange={handleSizeChange}
                setIsFileLoaded={setIsFileLoaded}
              />
            )}
          </div>
          <div className="messageDate" style={{ flexDirection: isMultiline ? 'column' : 'row' }}>
            <p>{getDateTime().format(message.createdAt, 'hourMinutes')}</p>
            {message.errorAt ? (
              <span ref={errorRef} onMouseEnter={isMobile ? () => {} : onErrorEnter}
                onMouseLeave={isMobile ? () => {} : onErrorLeave} onClick={onInfoClick}>
                {getMessageStatusIcon(message)}
                {infoOpen && (
                  <MobileInfo setIsOpen={setInfoOpen} background='light'
                    message={errorText.length > 100 ? errorText.slice(0, 100) + '...' : errorText} />
                )}
              </span>
            ) : (
              getMessageStatusIcon(message)
            )}
          </div>
          <span className="message_tail">
            <MessageTail />
          </span>
        </div>
      </div>
    </>
  );
});

export default ChatMessage;
