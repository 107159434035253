import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import { integrationsUpdate } from '../../views/Settings/Integrations/integrationSlice';
import {
  addRoleMember, changePermissionRoleMember, changeRoleMember,
  removeMember, updateCompany
} from '../../views/Chat/companiesSlice';
import { updateContactEdited } from '../../views/Contacts/contactsSlice/contactsSlice';
import { addSubscriptionInvoice, removePaymentRequisite } from '../../views/Settings/Subscriptions/SubscriptionsSlice';
import { editCatalog, newCatalog } from '../../views/Sales/SalesSlice';
import { deleteShop, updateShop } from '../../views/Sales/Shops/shopsSlice';
import {
  addChatToBlackList, createConnection, removeConnection, fetchChatsBlackList, removeChatFromBlackList,
  updateConnection, disableWabaCatalog,
} from '../../views/Settings/Connections/connectionsSlice';
import { createBroadcast, updateBroadcast, getBroadcast, getBroadcastWabaTemplates } from '../../views/Broadcasts/BroadcactsSlice';
import {
  createCustomTemplate, createInteractiveTemplate, editCustomTemplate, editInteractiveTemplate, fetchWabaTemplates,
  createWabaTemplate, fetchListWabaTemplates, deleteWabaTemplate, updateWabaTemplate
} from '../../views/Templates/templatesSlice';
import {
  addPartnerCompanyInvoice, createPartnerNotificationChannel, createPartnerPayout, removePartnerNotificationChannel,
  removePartnerRequisite, updatePartnerNotificationChannel, updatePartnerRequisite
} from '../../views/PartnerCabinet/PartnerCabinetSlice';
import {
  initTelegramSession, sendTelegramLogin
} from '../../views/Settings/Connections/ConnectionsViews/Telegram/TelegramPersonal/thunk';
import {
  createNotificationsChannel, deleteNotificationChannel,
  getNotificationsChannels, updateNotificationsChannel,
} from '../../views/Settings/Notifications/NotificationsSlice';


export type AlertStyle = 'alarm' | 'info';

export type AlertInner = 'broadcasts_waba_dialog' | 'connections_waba_catalogs' | 'error_call_support';

export type AlertType = {
  code?: number;
  identificator: string | number;
  payload?: string;
  type?: AlertStyle;
};

type PayloadTitled = {
  title: string;
  text: string;
};

export type AlertWithClosingType = {
  code?: number;
  payload?: string | PayloadTitled;
  type?: AlertStyle;
  inner?: AlertInner;
};

type AlertsInitialSlice = {
  alerts: AlertType[];
  alertWithClosing: null | AlertWithClosingType;
};

const initialState: AlertsInitialSlice = {
  alerts: [],
  alertWithClosing: null
};

const generateId = () => nanoid();

const alertSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    removeAlert: (state, action: PayloadAction<AlertType>) => {
      const alertIndex = state.alerts.findIndex((alert) => alert.identificator === action.payload.identificator);
      if (alertIndex !== -1) {
        state.alerts.splice(alertIndex, 1);
      }
    },
    addAlertWithCustomText: (state, action: PayloadAction<{
      message?: string; code?: number; color?: string; type?: AlertStyle }>) => {
      state.alerts.push({
        payload: action.payload.message,
        code: action.payload.code,
        type: action.payload.color && action.payload.color === '#F54242' ? 'alarm' : action.payload.type,
        identificator: generateId()
      });
    },
    setAlertWithClosing: (state, action: PayloadAction<null|AlertWithClosingType>) => {
      state.alertWithClosing = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateConnection.fulfilled, (state) => {
        state.alerts.push({ code: 100000, identificator: generateId() });
      })
      .addCase(updateConnection.rejected, (state, action) => {
        state.alerts.push({
          code: (action.meta.arg.type === 'odnoklassniki' && action.payload === 10001 ? 16100 : action.payload),
          identificator: generateId() });
      })
      .addCase(createConnection.rejected, (state, action) => {
        state.alerts.push({
          code: (action.meta.arg.type === 'odnoklassniki' && action.payload === 10001 ? 16100 : action.payload),
          identificator: generateId() });
      })
      .addCase(removeConnection.rejected, (state, action) => {
        state.alerts.push({ code: action.payload, identificator: generateId() });
      })
      .addCase(integrationsUpdate.fulfilled, (state, action) => {
        if(!action.meta.arg.requestOption.params?.resetApiKey){
          state.alerts.push({
            code: 100000,
            identificator: generateId(),
          });
        }
      })
      .addCase(updateContactEdited.fulfilled, (state) => {
        state.alerts.push({
          code: 100000,
          identificator: generateId(),
        });
      })
      .addCase(updateContactEdited.rejected, (state,action) => {
        state.alerts.push({
          code: action.payload!,
          identificator: generateId(),
        });
      })
      .addCase(integrationsUpdate.rejected, (state, action) => {
        state.alerts.push({
          code: action.payload!,
          identificator: generateId(),
        });
      })
      .addCase(createNotificationsChannel.rejected, (state, action) => {
        state.alerts.push({
          code: action.payload!,
          identificator: generateId(),
        });
      })
      .addCase(getNotificationsChannels.rejected, (state, action) => {
        state.alerts.push({
          code: action.payload!,
          identificator: generateId(),
        });
      })
      .addCase(deleteNotificationChannel.rejected, (state, action) => {
        state.alerts.push({
          code: action.payload!,
          identificator: generateId(),
        });
      })
      .addCase(createNotificationsChannel.fulfilled, (state) => {
        state.alerts.push({
          payload: 'settings.notifications.success',
          identificator: generateId(),
        });
      })
      .addCase(updateNotificationsChannel.fulfilled, (state) => {
        state.alerts.push({
          code: 100000,
          identificator: generateId(),
        });
      })
      .addCase(updateNotificationsChannel.rejected, (state, action) => {
        state.alerts.push({
          code: action.payload!,
          identificator: generateId(),
        });
      })
      .addCase(changeRoleMember.fulfilled, (state) => {
        state.alerts.push({
          code: 100000,
          identificator: generateId(),
        });
      })
      .addCase(addRoleMember.rejected, (state, action) => {
        state.alerts.push({
          code: action.payload!,
          identificator: generateId(),
        });
      })
      .addCase(changeRoleMember.rejected, (state, action) => {
        state.alerts.push({
          code: action.payload!,
          identificator: generateId(),
        });
      })
      .addCase(changePermissionRoleMember.rejected, (state, action) => {
        state.alerts.push({
          code: action.payload!,
          identificator: generateId(),
        });
      })
      .addCase(removeMember.rejected, (state, action) => {
        state.alerts.push({
          code: action.payload!,
          identificator: generateId(),
        });
      })
      .addCase(addSubscriptionInvoice.rejected, (state, action) => {
        state.alerts.push({
          code: action.payload!,
          identificator: generateId(),
        })
      })
      .addCase(newCatalog.fulfilled, (state) => {
        state.alerts.push({ payload: 'sales.catalogs.create_success', identificator: generateId() });
      })
      .addCase(newCatalog.rejected, (state, action) => {
        state.alerts.push({ code: action.payload, identificator: generateId() });
      })
      .addCase(editCatalog.fulfilled, (state) => {
        state.alerts.push({ code: 100000, identificator: generateId() });
      })
      .addCase(editCatalog.rejected, (state, action) => {
        state.alerts.push({ code: action.payload!, identificator: generateId() });
      })
      .addCase(updateShop.fulfilled, (state) => {
        state.alerts.push({
          code: 100000,
          identificator: generateId(),
        });
      })
      .addCase(updateCompany.fulfilled, (state) => {
        state.alerts.push({
          code: 100000,
          identificator: generateId(),
        });
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.alerts.push({
          code: action.payload!,
          identificator: generateId(),
        })
      })
      .addCase(updateShop.rejected, (state, action) => {
        state.alerts.push({
          code: action.payload!,
          identificator: generateId(),
        })
      })
      .addCase(deleteShop.rejected, (state, action) => {
        state.alerts.push({
          code: action.payload!,
          identificator: generateId(),
        })
      })
      .addCase(removeChatFromBlackList.fulfilled, (state) => {
        state.alerts.push({ payload: 'connections.black_list.alert_text', identificator: generateId() });
      })
      .addCase(removeChatFromBlackList.rejected, (state, action) => {
        state.alerts.push({ code: action.payload, identificator: generateId() });
      })
      .addCase(addChatToBlackList.fulfilled, (state) => {
        state.alerts.push({ payload: 'connections.black_list.alert_text', identificator: generateId() });
      })
      .addCase(addChatToBlackList.rejected, (state, action) => {
        state.alerts.push({ code: action.payload, identificator: generateId() });
      })
      .addCase(fetchChatsBlackList.rejected, (state, action) => {
        state.alerts.push({ code: action.payload, identificator: generateId() });
      })
      .addCase(createCustomTemplate.fulfilled, (state) => {
        state.alerts.push({ payload: 'templates.add_templates.success', identificator: generateId() });
      })
      .addCase(editCustomTemplate.fulfilled, (state) => {
        state.alerts.push({ code: 100000, identificator: generateId() });
      })
      .addCase(createInteractiveTemplate.fulfilled, (state) => {
        state.alerts.push({ payload: 'templates.add_templates.success', identificator: generateId() });
      })
      .addCase(editInteractiveTemplate.fulfilled, (state) => {
        state.alerts.push({ code: 100000, identificator: generateId() });
      })
      .addCase(createBroadcast.fulfilled, (state, action) => {
        let message: string = '';
        if (action.meta.arg.planned_time) {
          message = 'broadcasts.alert.planned';
        } else {
          message = 'broadcasts.alert.started';
        }
        state.alerts.push({ payload: message, identificator: generateId() });
      })
      .addCase(createBroadcast.rejected, (state, action) => {
        state.alerts.push({ code: action.payload, identificator: generateId() });
      })
      .addCase(updateBroadcast.fulfilled, (state, action) => {
        let message: string = '';
        if (action.meta.arg.planned_time) {
          message = 'broadcasts.alert.edited';
        } else {
          message = 'broadcasts.alert.started';
        }
        state.alerts.push({ payload: message, identificator: generateId() });
      })
      .addCase(updateBroadcast.rejected, (state, action) => {
        state.alerts.push({ code: action.payload, identificator: generateId() });
      })
      .addCase(getBroadcast.rejected, (state, action) => {
        state.alerts.push({ code: action.payload, identificator: generateId() });
      })
      .addCase(getBroadcastWabaTemplates.rejected, (state, action) => {
        if (action.payload !== 11) {
          // exception for 11 - not in subscription error
          state.alerts.push({ code: action.payload, identificator: generateId() });
        }
      })
      .addCase(fetchWabaTemplates.rejected, (state, action) => {
        state.alerts.push({
          code: action.payload!,
          identificator: generateId(),
        });
      })
      .addCase(disableWabaCatalog.fulfilled, (state) => {
        state.alerts.push(
          { payload: 'connections.waba.catalogs.disable.disabled', type: 'info', identificator: generateId() });
      })
      .addCase(fetchListWabaTemplates.rejected, (state, action) => {
        if (typeof(action.payload) === 'number') {
          state.alerts.push({ code: action.payload, identificator: generateId() });
        } else {
          state.alerts.push({ payload: action.payload, type: 'alarm', identificator: generateId() });
        }
      })
      .addCase(createWabaTemplate.fulfilled, (state) => {
        state.alerts.push({ payload: 'templates.add_templates.success', type: 'info', identificator: generateId() });
      })
      .addCase(createWabaTemplate.rejected, (state, action) => {
        if (typeof(action.payload) === 'number') {
          state.alerts.push({ code: action.payload, identificator: generateId() });
        } else {
          state.alertWithClosing = { payload: action.payload, type: 'alarm' };
        }
      })
      .addCase(deleteWabaTemplate.rejected, (state, action) => {
        if (typeof(action.payload) === 'number') {
          state.alerts.push({ code: action.payload, identificator: generateId() });
        } else {
          state.alertWithClosing = { payload: action.payload, type: 'alarm' };
        }
      })
      .addCase(deleteWabaTemplate.fulfilled, (state) => {
        state.alerts.push({ payload: 'templates.waba_list.deleted_template', type: 'info', identificator: generateId() });
      })
      .addCase(updateWabaTemplate.rejected, (state, action) => {
        state.alerts.push({ code: action.payload, identificator: generateId() });
      })
      .addCase(addPartnerCompanyInvoice.rejected, (state, action) => {
        state.alerts.push({ code: action.payload, identificator: generateId() });
      })
      .addCase(sendTelegramLogin.rejected, (state, action) => {
        state.alerts.push({ code: action.payload, identificator: generateId() });
      })
      .addCase(initTelegramSession.rejected, (state, action) => {
        state.alerts.push({ code: action.payload, identificator: generateId() });
      })
      .addCase(removePartnerRequisite.fulfilled, (state) => {
        state.alerts.push(
          { payload: 'requisites.actions.delete_success', type: 'info', identificator: generateId() });
      })
      .addCase(removePartnerRequisite.rejected, (state) => {
        state.alerts.push(
          { payload: 'requisites.actions.delete_error', type: 'info', identificator: generateId() });
      })
      .addCase(removePaymentRequisite.fulfilled, (state) => {
        state.alerts.push(
          { payload: 'requisites.actions.delete_success', type: 'info', identificator: generateId() });
      })
      .addCase(removePaymentRequisite.rejected, (state) => {
        state.alerts.push(
          { payload: 'requisites.actions.delete_error', type: 'info', identificator: generateId() });
      })
      .addCase(updatePartnerRequisite.rejected, (state, action) => {
        state.alerts.push({ code: action.payload, identificator: generateId() });
      })
      .addCase(createPartnerNotificationChannel.rejected, (state, action) => {
        state.alerts.push({
          code: action.payload!,
          identificator: generateId(),
        });
      })
      .addCase(removePartnerNotificationChannel.rejected, (state, action) => {
        state.alerts.push({ code: action.payload, identificator: generateId() });
      })
      .addCase(updatePartnerNotificationChannel.rejected, (state, action) => {
        state.alerts.push({ code: action.payload, identificator: generateId() });
      })
      .addCase(createPartnerPayout.rejected, (state, action) => {
        state.alerts.push({ code: action.payload, identificator: generateId() });
      })
  },
});

export const { removeAlert, addAlertWithCustomText, setAlertWithClosing } = alertSlice.actions;

export default alertSlice.reducer;
