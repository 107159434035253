import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { paymentArr, taxArr, taxBepaidArr, typeArr } from '../../../../../utils/payments';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { useAmoInvoicesStatuses, useConnectionsInfo } from '../../api';
import { useBankingUpdate } from '../../api/banking';
import { BankingSchema } from '../../types';
import { integrationContext } from '../../utils';
import { terminalsContext, useTerminals } from '../../api/terminals';
import AmoInvoiceStatuses from './AmoInvoiceStatuses';
import Button from '../../../../../components/Button/Button';
import SwitchControl from '../../../../../components/SwitchControl';
import Switcher from '../../../../../components/Switcher';
import Dropdown from '../../../Dropdown/Dropdown';
import ConnectionAdd from '../ConnectionAdd';
import EditName from '../EditName';
import TerminalList from './TerminalList';
import SidePanel from '../../../../../components/Modals/SidePanel';
import SwitchRequest from '../SwitchRequest';
import './Banking.scss';


function Banking() {
  const { t } = useTranslation();
  const { service, integration } = useContext(integrationContext);
  const { available, connected, domain } = useConnectionsInfo(integration.id);
  const { onDelete, onInsert, ...terminalsSettings } = useTerminals({ available, connected, integration });
  const updateIntegration = useBankingUpdate();
  const { amoStatus, fetched, invoiceStatuses, createInvoiceStatuses, fetchAmoInvoicesStatuses } =
    useAmoInvoicesStatuses(integration);
  const [switchInvoice, setSwitchInvoice] = useState<boolean>(false);
  const [onAmoSettings, setOnAmoSettings] = useState<boolean>(false);
  const [switchRequest, setSwitchRequest] = useState<boolean>(false);

  const taxArrIntegration = integration.type === 'bepaidAmocrm' ? taxBepaidArr : taxArr;

  const defaultValues: BankingSchema = {
    createFields: integration?.params?.createFields,
    defaultConnection: String(integration?.params?.defaultConnection),
    extraPayment: integration?.params?.extra?.payment,
    extraTax: integration?.params?.extra?.tax,
    extraType: integration?.params?.extra?.type,
    amoInvoices: integration?.params?.amoInvoices,
    status: integration?.status === 'enabled',
  };

  const methods = useForm<BankingSchema>({ mode: 'onSubmit', defaultValues });
  const watchAmoInvoices = useWatch({ control: methods.control, name: 'amoInvoices' });

  const saveData = async (data: BankingSchema) => {
    const result = await updateIntegration({
      ...data,
      id: integration.id, name: integration.name, type: integration.type, terminals: terminalsSettings.terminals
    });
    if (result) {
      methods.reset(data);
      terminalsSettings.onReset();
    }
  };

  const onSwitchOn = () => {
    setSwitchRequest(false);
    const data = methods.getValues();
    saveData({ ...data, status: !data.status });
  };

  const onSwitchOff = () => {
    setSwitchRequest(false);
    saveData(methods.getValues());
  };

  const onSubmit = (data: BankingSchema) => {
    if (!data.status && integration.status === 'disabled') {
      setSwitchRequest(true);
    } else {
      saveData(data);
    }
  };

  const updateName = async (name: string) => await updateIntegration({
    name, id: integration.id, type: integration.type, ...defaultValues, terminals: terminalsSettings.initTerminals
  });

  useEffect(() => {
    if (!onAmoSettings) setSwitchInvoice(!!watchAmoInvoices);
  }, [watchAmoInvoices, onAmoSettings]);

 const onSwitchInvoice = async () => {
    let result: boolean;
    result = fetched;
    if (!result) {
      result = await fetchAmoInvoicesStatuses();
    }
    if (switchInvoice && result) {
      methods.setValue('amoInvoices', {
        invoiceCatalogId: 0,
        statuses: { confirmed: 0, expired: 0, new: 0, partialRefunded: 0, refunded: 0, rejected: 0 }
      },
        { shouldDirty: true });
      methods.setValue('amoInvoices', null, { shouldDirty: true });
    } else {
      setOnAmoSettings(amoStatus);
    }
    setSwitchInvoice(!!result);
  };

  const onAmoSettingsHandler = async () => {
    let result: boolean;
    result = fetched;
    if (!result){
      result = await fetchAmoInvoicesStatuses();
    }
    setSwitchInvoice(result);
    setOnAmoSettings(result);
  };

  return (
    <div className='banking'>
      {switchRequest && <SwitchRequest onAction={onSwitchOn} onClose={onSwitchOff} setIsOpen={setSwitchRequest} />}
      <EditName integration={integration} onUpdate={updateName} />
      <p className='regularText middle'>{t('settings.integrations.domain')}: {domain}</p>
      <p className='regularText middle'>{t('settings.integrations.integration_id')}: {integration.id}</p>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {onAmoSettings &&
            <SidePanel setIsOpen={setOnAmoSettings} background="light">
              <AmoInvoiceStatuses invoiceStatuses={invoiceStatuses} setFormOpen={setOnAmoSettings}
                createStatuses={createInvoiceStatuses} />
            </SidePanel>
          }
          <h3>{t('settings.integrations.banking.subtitle1')}</h3>
          <WrapperPermission permission='integrationsEdit' disabledProps={{ disabled: true }}>
            <Dropdown label={t('settings.integrations.banking.type')} name='extraType'
              items={typeArr} valueKey='id' labelKey='name' />
          </WrapperPermission>
          <WrapperPermission permission='integrationsEdit' disabledProps={{ disabled: true }}>
            <Dropdown label={t('settings.integrations.banking.tax')} name='extraTax'
              items={taxArrIntegration} valueKey='id' labelKey='name' />
          </WrapperPermission>
          <WrapperPermission permission='integrationsEdit' disabledProps={{ disabled: true }}>
            <Dropdown label={t('settings.integrations.banking.payment')} name='extraPayment'
              items={paymentArr} valueKey='id' labelKey='name' />
          </WrapperPermission>
          <WrapperPermission permission='integrationsEdit' disabledProps={{ disabled: true }}>
            <SwitchControl label={t('settings.integrations.banking.checkbox1')} name='createFields' />
          </WrapperPermission>
          <div className='banking__switch'>
            <WrapperPermission permission='integrationsEdit' disabledProps={{ disabled: true }}>
              <Switcher toggled={switchInvoice} onToggle={onSwitchInvoice}
                lable={t('settings.integrations.banking.checkbox4')} />
            </WrapperPermission>
            <WrapperPermission permission='integrationsEdit' >
              <Button type='button' color='white' textType='small'
                text={t('settings.integrations.settings_integrations.tune')}
                onClick={onAmoSettingsHandler} />
            </WrapperPermission>
          </div>
          <WrapperPermission permission='integrationsEdit'>
            <>
              <h3>{t('settings.integrations.banking.connect')}&nbsp;
                {t(`settings.integrations.${service}.title`)}
              </h3>
              <ConnectionAdd addons={terminalsSettings.addons} onInsert={onInsert}
                caption={t('settings.integrations.banking.available_connections')} />
            </>
          </WrapperPermission>
          <h3>{t('settings.integrations.terminal.connectedTerminals')}</h3>
          <terminalsContext.Provider value={{ onDelete }}>
            <TerminalList terminals={terminalsSettings.terminals} />
          </terminalsContext.Provider>
          <div className='banking__footer'>
            <WrapperPermission permission='integrationsEdit' disabledProps={{ disabled: true }}>
              <SwitchControl name='status' label={t('settings.integrations.status')} />
            </WrapperPermission>
            <WrapperPermission permission='integrationsEdit'>
              <Button type='submit' color='orange' textType='regular' text={t('save_edit')}
                disabled={!methods.formState.isDirty && !terminalsSettings.isChanged} />
            </WrapperPermission>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default Banking;
